// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aviso-legal-jsx": () => import("./../../../src/pages/aviso-legal.jsx" /* webpackChunkName: "component---src-pages-aviso-legal-jsx" */),
  "component---src-pages-condiciones-generales-jsx": () => import("./../../../src/pages/condiciones-generales.jsx" /* webpackChunkName: "component---src-pages-condiciones-generales-jsx" */),
  "component---src-pages-etica-y-cumplimiento-jsx": () => import("./../../../src/pages/etica-y-cumplimiento.jsx" /* webpackChunkName: "component---src-pages-etica-y-cumplimiento-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-politica-de-cookies-jsx": () => import("./../../../src/pages/politica-de-cookies.jsx" /* webpackChunkName: "component---src-pages-politica-de-cookies-jsx" */),
  "component---src-pages-politica-de-privacidad-jsx": () => import("./../../../src/pages/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-jsx" */),
  "component---src-pages-quienes-somos-jsx": () => import("./../../../src/pages/quienes-somos.jsx" /* webpackChunkName: "component---src-pages-quienes-somos-jsx" */),
  "component---src-pages-sistema-interno-de-informacion-jsx": () => import("./../../../src/pages/sistema-interno-de-informacion.jsx" /* webpackChunkName: "component---src-pages-sistema-interno-de-informacion-jsx" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */)
}

